<template lang="html">
  <v-container pa-0 fluid>
    <v-row>

      <v-col cols="12">
        <v-card>
          <v-document-scan hn="19000018" category="11"></v-document-scan>
        </v-card>
        
      </v-col>
      <v-col cols="12">

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
}
</script>

<style lang="css" scoped>
</style>
